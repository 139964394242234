export const ALERT_FOLDERS_REQUEST = 'ALERT_FOLDERS_REQUEST';
export const ALERT_FOLDERS_SUCCESS = 'ALERT_FOLDERS_SUCCESS';
export const ALERT_FOLDERS_ERROR = 'ALERT_FOLDERS_ERROR';

export const ALERT_MENTIONS_REQUEST = 'ALERT_MENTIONS_REQUEST';
export const ALERT_MENTIONS_SUCCESS = 'ALERT_MENTIONS_SUCCESS';
export const ALERT_MENTIONS_ERROR = 'ALERT_MENTIONS_ERROR';

export const ALERT_MENTIONS_STATISTICS_REQUEST = 'ALERT_MENTIONS_STATISTICS_REQUEST';
export const ALERT_MENTIONS_STATISTICS_SUCCESS = 'ALERT_MENTIONS_STATISTICS_SUCCESS';
export const ALERT_MENTIONS_STATISTICS_ERROR = 'ALERT_MENTIONS_STATISTICS_ERROR';

