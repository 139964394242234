export const KEYWORD_SEARCHING_REQUEST = 'KEYWORD_SEARCHING_REQUEST';
export const KEYWORD_SEARCHING_SUCCESS = 'KEYWORD_SEARCHING_SUCCESS';
export const KEYWORD_SEARCHING_ERROR = 'KEYWORD_SEARCHING_ERROR';

export const KEYWORD_SEARCHING_STATISTICS_REQUEST = 'KEYWORD_SEARCHING_STATISTICS_REQUEST';
export const KEYWORD_SEARCHING_STATISTICS_SUCCESS = 'KEYWORD_SEARCHING_STATISTICS_SUCCESS';
export const KEYWORD_SEARCHING_STATISTICS_ERROR = 'KEYWORD_SEARCHING_STATISTICS_ERROR';

export const KEYWORD_DETAILS_REQUEST = 'KEYWORD_DETAILS_REQUEST';
export const KEYWORD_DETAILS_SUCCESS = 'KEYWORD_DETAILS_SUCCESS';
export const KEYWORD_DETAILS_ERROR = 'KEYWORD_DETAILS_ERROR'